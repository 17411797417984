import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import LogoSVG from '../images/inline/logo-footer.svg';

const Footer = () => {
  const {
    site: {
      siteMetadata: {
        companyName,
        contact: { facebook, twitter, email },
      },
    },
  } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          companyName
          contact {
            facebook {
              label
              url
            }
            twitter {
              label
              url
            }
            email
          }
        }
      }
    }
  `);

  const copyright = `&copy; ${companyName} ${new Date().getFullYear()}`;

  return (
    <footer className="site-footer">
      <div className="row">
        <div className="columns small-12 text-center">
          <LogoSVG />
          <hr className="divider" />
        </div>
      </div>
      <div className="row">
        <div className="columns small-12 medium-2">
          {/* eslint-disable-next-line react/no-danger */}
          <p dangerouslySetInnerHTML={{ __html: copyright }} />
        </div>
        <div className="columns small-12 medium-8">
          <ul className="social-media">
            <li>
              <a href={facebook.url}>{facebook.label}</a>
            </li>
            <li>
              <a href={twitter.url}>{twitter.label}</a>
            </li>
            <li>
              <a href={`mailto:${email}`}>{email}</a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
