import React, { useState } from 'react';
import { FormattedMessage, IntlContextConsumer, changeLocale, Link } from 'gatsby-plugin-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import NexttLogoImg from '../images/inline/nextt-logo.svg';

const Header = () => {
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  return (
    <IntlContextConsumer>
      {({ languages }) => {
        return (
          <nav className="top-bar">
            <Link to="/#intro" className="nav-item">
              <NexttLogoImg />
            </Link>
            <button type="button" className="toggle-topbar menu-icon link" onClick={() => setMobileNavOpen(true)}>
              <FontAwesomeIcon icon={faBars} />
            </button>
            <ul
              className={mobileNavOpen ? `open` : ``}
              onClick={() => setMobileNavOpen(false)}
              onKeyPress={() => {}}
              role="presentation"
            >
              <span className="toggle-topbar close-icon" />
              <li>
                <Link to="/#how-we-do" className="nav-item">
                  <FormattedMessage id="header.menu.about" />
                </Link>
              </li>
              <li>
                <Link to="/#meet-our-clients" className="nav-item">
                  <FormattedMessage id="header.menu.clients" />
                </Link>
              </li>
              <li>
                <Link to="/#work-with-us" className="nav-item">
                  <FormattedMessage id="header.menu.work_with_us" />
                </Link>
              </li>
              <li>
                <Link to="/#contact-us" className="nav-item">
                  <FormattedMessage id="header.menu.contact" />
                </Link>
              </li>
              <li>
                <Link to="/privacidade" className="nav-item">
                  <FormattedMessage id="header.menu.privacy" />
                </Link>
              </li>
              <li className="divider" />
              {languages.map(locale => (
                <li key={locale}>
                  <button type="button" className="link nav-item" onClick={() => changeLocale(locale)}>
                    {locale.toUpperCase()}
                  </button>
                </li>
              ))}
            </ul>
          </nav>
        );
      }}
    </IntlContextConsumer>
  );
};

export default Header;
