/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useIntl } from 'gatsby-plugin-intl';
import { useLocation } from '@reach/router';
import { useStaticQuery, graphql } from 'gatsby';
import i18nMessages from '../lang/messages';

function SEO({ meta, title, description }) {
  const intl = useIntl();
  const { pathname } = useLocation();

  const {
    site: {
      siteMetadata: {
        siteUrl,
        contact: { address, coordinates, phone, email },
      },
    },
    shareImage,
  } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteUrl
          contact {
            address {
              street
              neighborhood
              city
              state
              country
              countryCode
              zip
            }
            coordinates {
              lat
              lng
            }
            phone
            email
          }
        }
      }
      shareImage: file(relativePath: { eq: "bgs/facebook.jpg" }) {
        size: childImageSharp {
          original {
            src
          }
        }
      }
    }
  `);

  return (
    <Helmet
      htmlAttributes={{
        lang: intl.locale,
      }}
      title={title ? `${title} | Nextt` : i18nMessages[intl.locale]['meta.title']}
      // titleTemplate={`%s | ${i18nMessages[intl.locale]['meta.title']}`}
      meta={[
        { name: `description`, content: description || i18nMessages[intl.locale]['meta.desc'] },
        { name: `author`, content: 'Nextt' },
        { name: 'copyright', content: 'www.nextt.com.br' },
        { name: 'geo.placename', content: `${address.street} - ${address.city}, ${address.state}, ${address.country}` },
        { name: 'geo.position', content: `${coordinates.lat};${coordinates.lng}` },
        { name: 'geo.region', content: `${address.state}-${address.countryCode}` },
        { name: 'ICBM', content: `${coordinates.lat},${coordinates.lng}` },
        { property: `og:title`, content: title || i18nMessages[intl.locale]['meta.title'] },
        { property: `og:description`, content: description || i18nMessages[intl.locale]['meta.desc'] },
        { property: `og:type`, content: `website` },
        { property: 'og:image', content: `${siteUrl}${shareImage.size.original.src}` },
        { property: 'og:image:type', content: 'image/jpeg' },
        { property: 'og:image:width', content: '398' },
        { property: 'og:image:height', content: '208' },
        { property: 'og:url', content: `${siteUrl}${pathname}` },
        { property: 'og:latitude', content: coordinates.lat },
        { property: 'og:longitude', content: coordinates.lng },
        { property: 'og:street-address', content: address.street },
        { property: 'og:locality', content: address.city },
        { property: 'og:region', content: address.state },
        { property: 'og:country-name', content: address.country },
        { property: 'og:email', content: email },
        { property: 'og:phone_number', content: phone },
        { name: `twitter:card`, content: `summary` },
        { name: `twitter:creator`, content: '@nexttbr' },
        { name: `twitter:title`, content: title ? `${title} | Nextt` : i18nMessages[intl.locale]['meta.title'] },
        { name: `twitter:description`, content: description || i18nMessages[intl.locale]['meta.desc'] },
      ].concat(meta)}
    />
  );
}

SEO.defaultProps = {
  meta: [],
  description: ``,
  title: ``,
};

SEO.propTypes = {
  description: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
};

export default SEO;
