import React from 'react';
import PropTypes from 'prop-types';
import Footer from './footer';
import Header from './header';
import Modal from './modal';
import './modal.scss';

const Layout = ({ children }) => {
  if (typeof window !== 'undefined') {
    // eslint-disable-next-line global-require
    require('smooth-scroll')('a[href*="#"]');
  }
  return (
    <>
      <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
      <Header />
      <div className="background">
        <div id="content" className="site-content">
          {children}
        </div>
        <Footer />
      </div>
      <Modal />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default Layout;
